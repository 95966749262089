// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getDatabase, ref, onValue } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_nTgHbPfPdLBSH71pb1UAncmAPq7KiwE",
  authDomain: "hy-nailart.firebaseapp.com",
  projectId: "hy-nailart",
  storageBucket: "hy-nailart.appspot.com",
  messagingSenderId: "588906824086",
  appId: "1:588906824086:web:56fa00fe5fcf9baecb5db7",
  databaseURL: "https://hy-nailart-default-rtdb.asia-southeast1.firebasedatabase.app/",
  measurementId: "G-9ZEQYGJHHR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeFirestore(app, 
  {localCache: 
    persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()})
});
const firestore = getFirestore(app);
const db = getDatabase(app)
const analytics = getAnalytics(app);

export { app, firestore, db };