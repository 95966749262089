import React, { useContext, useEffect, useState } from 'react';
import {
    getAuth, getIdToken, onAuthStateChanged, signInWithEmailAndPassword
} from "firebase/auth";
import { app } from '../Firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { SyncLoader } from 'react-spinners';
import { redirect, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    // assume user to be logged out
    const [loggedIn, setLoggedIn] = useState(false);
    const [firebaseProfile, setFirebaseProfile] = useState(null)
    const [profile, setProfile] = useState(null)

    // keep track to display a spinner while auth status is being checked
    const [checkingStatus, setCheckingStatus] = useState(true);

    useEffect(() => {
        const listener = onAuthStateChanged(getAuth(app), async (user) => {
            // console.log(user)
            if (user) {
                // handleSignedInUser(user)
                var res = [];
                // const q = query(collection(db, "users"), where("__name__", "==", user.uid));

                // const querySnapshot = await getDocs(q);
                // querySnapshot.forEach((doc) => {
                //     // doc.data() is never undefined for query doc snapshots
                //     // console.log(doc.id, " => ", doc.data());
                //     res.push(doc.data())
                // });
                // if (res) {
                setLoggedIn(true);
                // setProfile(res[0])
                setFirebaseProfile(user)
                // }
            } else {
                // const axiosInstance = createAxiosInstance('');
                // try {
                // await axiosInstance.get('/logout')
                setLoggedIn(false);
                setProfile(null);
                setFirebaseProfile(null)
                // } catch (err) {
                //     console.log(err)
                // }

            }
            setCheckingStatus(false);
        });
        return () => {
            listener();
        }
    }, []);

    const values = {
        loggedIn,
        firebaseProfile,
        profile,
        checkingStatus
    }
    AuthProvider.propTypes = {
        children: PropTypes.node.isRequired,
    };

    return <AuthContext.Provider value={values}>
        {!checkingStatus && children ? children :
            <SyncLoader loading={checkingStatus} style={{ zIndex: '999', position: 'fixed', right: '47vw', bottom: '50vh' }} />}
    </AuthContext.Provider>;
};
